export const colors = {
  // brand colors
  primaryAccent: "#fcfcff",
  primaryAccentMedium: "#f6f9fc",
  primaryAccentLighten: "#faf9fe",
  primaryLighten: "#e3eaf2",
  primaryLightMedium: "#d4dde8",
  primaryLightMedium04: "rgba(212, 221, 232, 0.4)",
  primaryLight: "#c3cddf",
  primaryLight01: "rgba(195, 205, 223, 0.1)", // #c3cddf with opacity 0.1
  primary: "#7163e7",
  primaryDim: "#eae8fc",
  primaryLightDim: "#eaeaff",
  primaryMedium: "#786aee",
  primary005: "rgba(113, 99, 231, 0.05)",
  primary01: "rgba(113, 99, 231, 0.1)",
  primary034: "rgba(113, 99, 231, 0.34)",
  primary05: "rgba(113, 99, 231, 0.5)",
  primaryDark: "#362e7c",
  primaryDarkGrey: "#343a5f",

  // background
  backgroundLightPurple: "#eeecfc",

  // navigation
  navigationPrimaryHQ: "#4d43a7",
  navigationPrimaryHQDark: "#373166",
  navigationPrimaryAdmin: "#2b2b4f",

  // text
  textBlueSteel: "#5d7698",
  textLighten: "#b4bed4",
  textGraphite: "#607086",
  textPrimary: "#6565c1",
  textLight: "rgba(107, 124, 147, 0.6)", // #6b7c93 with opacity 0.6
  text: "#9999ad",
  text07: "rgba(153, 153, 173, 0.7)", // #9999ad with opacity 0.7
  textAccent: "#6b7c93",
  textMedium: "#54617a",
  textDark: "#252a34",
  textDark024: "rgba(37, 42, 52, 0.24)", // #252a34 with opacity 0.24

  // states
  errorAccent: "#fff9fa",
  error: "#e91e45",
  errorDim: "#fef4f5",
  errorPrimary: "#da0c34",
  error01: "rgba(233, 30, 69, 0.05)",
  error05: "rgba(233, 30, 69, 0.5)",
  warningAccent: "#fffef8",
  warning: "#ffd03b",
  warningDim: "#fffaeb",
  warning05: "rgba(255, 208, 59, 0.5)",
  warning01: "rgba(255, 208, 59, 0.1)",
  warningPrimary: "#ff7b16",
  success: "#09ce89",
  success005: "rgba(9, 206, 137, 0.05)",
  successPrimary: "#00c782",
  defaultGrey: "#e3e5f0",

  // dividers
  divider: "#d8d8e2",
  divider06: "rgba(216, 216, 226, 0.6)",
  divider04: "rgba(216, 216, 226, 0.4)",
  divider02: "rgba(216, 216, 226, 0.2)",

  // overlay
  cream: "#fafafa",
  overlay: "rgba(59, 67, 88, 0.79)",
  violet008: "rgba(113, 99, 231, 0.08)",
  violet05: "rgba(113, 99, 231, 0.5)",
  violet036: "rgba(113, 99, 231, 0.36)",
  blackLightShadow: "rgba(162, 162, 162, 0.5)",

  themeWhite: "#ffffff", // white that will change depending on theme
  themeBlack: "#000000",
  greyLightBorder: "#e6e6e6",

  // shadow constants
  blackShadow: "0,0,0",
  brandShadow: "0,29,91",
  greyShadow: "rgba(0, 0, 0, 0.03)",

  // theme independent colors
  black: "#000000",
  white: "#ffffff",
  white013: "rgba(255, 255, 255, 0.13)",
  transparent: "transparent",
  dirtyGray: "#e1ddd7",

  // notes
  aliceBlue: "#f3fbff",
  cerulean: "#03a9f4",
  swansDown: "#d3ebe4",
  polar: "#ecfbf6",
  mercury: "#e2e2e2",
  mercury041: "rgba(229, 229, 229, 0.41)",
  hokeyPokey: "#d6ae32",
  coralRed01: "rgba(255, 59, 59, 0.1)",
  coralRed03: "rgba(255, 59, 59, 0.3)",
};

export const buttons = {
  primary: {
    background: colors.primary,
    border: colors.transparent,
    textColor: colors.white,
    borderRadius: 40,
    hover: {
      background: colors.primaryMedium,
      border: colors.transparent,
      textColor: colors.white,
    },
    active: {
      background: colors.primaryDark,
    },
  },
  secondary: {
    background: colors.transparent,
    border: colors.primary,
    textColor: colors.primary,
    borderRadius: 40,
    hover: {
      background: colors.primaryAccent,
      border: colors.primaryMedium,
      textColor: colors.primaryMedium,
    },
    active: {
      background: colors.divider04,
    },
  },
  tertiary: {
    background: colors.transparent,
    border: colors.transparent,
    textColor: colors.primary,
    borderRadius: 40,
    hover: {
      background: colors.transparent,
      border: colors.transparent,
      textColor: colors.primaryDark,
    },
    active: {
      background: colors.divider04,
    },
  },
  square: {
    background: colors.transparent,
    border: colors.divider06,
    textColor: colors.primary,
    borderRadius: 5,
    hover: {
      background: colors.transparent,
      border: colors.divider06,
      textColor: colors.primaryDark,
    },
    active: {
      background: colors.primaryAccent,
    },
  },
  error: {
    background: colors.error,
    border: colors.error,
    textColor: colors.white,
    borderRadius: 40,
    hover: {
      background: colors.error,
      border: colors.errorPrimary,
      textColor: colors.white,
    },
    active: {
      background: colors.errorPrimary,
    },
  },
  success: {
    background: colors.success,
    border: colors.success,
    textColor: colors.white,
    borderRadius: 40,
    hover: {
      background: colors.success,
      border: colors.successPrimary,
      textColor: colors.white,
    },
    active: {
      background: colors.successPrimary,
    },
  },
  black: {
    background: colors.textDark,
    border: colors.textDark,
    textColor: colors.white,
    borderRadius: 40,
    hover: {
      background: colors.black,
      border: colors.black,
      textColor: colors.white,
    },
    active: {
      background: colors.textDark,
    },
  },
};

const statuses = {
  default: colors.text,
  error: colors.error,
  info: colors.primary,
  success: colors.success,
  warning: colors.warning,
};

export default {
  name: "DEFAULT",
  font: "Effra, Arial, sans-serif",
  ...colors,
  buttons,
  statuses,
};
